.ps-btn, button.ps-btn {
  background-color: #5fa30f;
  color: #ffffff;
}

.ps-btn:hover, button.ps-btn:hover {
  background-color: #518c0d;
}

.header--organic .header__extra span {
  background-color: #5fa30f;
}

.header--organic .header__extra:hover i {
  color: #5fa30f;
}

.header--organic .ps-block--user-header a:hover {
  color: #5fa30f;
}

.header--organic .menu--product-categories {
  display: block;
  margin-right: 20px;
}

.header--organic .menu--product-categories .menu__toggle:hover:before {
  background-color: #5fa30f;
}

.header--organic .ps-form--quick-search {
  width: 600px;
}

.header--organic .ps-form--quick-search button {
  background-color: #5fa30f;
  color: #ffffff;
}

.header--organic .ps-form--quick-search .form-group--icon {
  border: 1px solid #e1e1e1;
  min-width: 80px;
  border-radius: 4px 0 0 4px;
}

.header--organic .ps-form--quick-search .form-group--icon select {
  height: 40px;
  border: none;
}

.header--organic .ps-form--quick-search input {
  border: 1px solid #e1e1e1;
  border-left: none;
}

.header--organic .ps-block--header-hotline p {
  color: #000;
}

.header--organic .header__top {
  background-color: #ffffff;
}

.header--organic .navigation {
  background-color: #ffffff;
}

.header--organic .menu--organic > li > a:hover {
  color: #5fa30f;
}

.header--organic.header--sticky .menu--product-categories .menu__toggle span {
  display: block;
}

.header--organic.header--sticky .header__top {
  border-bottom: 1px solid #e1e1e1;
}

.header--mobile.organic {
  background-color: #fff;
}

.header--mobile.organic .ps-cart--mini {
  text-align: left;
}

.header--mobile.organic .ps-search--mobile {
  background-color: #5fa30f;
}

.header--mobile.organic.header--sticky .navigation--mobile {
  background-color: #ffffff;
}

.navigation--list .navigation__item.active i, .navigation--list .navigation__item.active span, .navigation--list .navigation__item:focus i, .navigation--list .navigation__item:focus span, .navigation--list .navigation__item:active i, .navigation--list .navigation__item:active span, .navigation--list .navigation__item:hover i, .navigation--list .navigation__item:hover span {
  color: #5fa30f;
}

.ps-panel--sidebar .ps-panel__header {
  background-color: #5fa30f;
}

.ps-banner--organic {
  position: relative;
}

.ps-banner--organic h4 {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #0098ce;
}

.ps-banner--organic h3 {
  font-size: 36px;
  font-weight: 300;
  line-height: 1.2em;
}

.ps-banner--organic h3 strong {
  font-weight: 600;
  color: #669900;
}

.ps-banner--organic .ps-btn {
  margin-top: 20px;
  padding: 12px 24px;
}

.ps-banner--organic .ps-banner__content {
  max-width: 1200px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding-left: 240px;
}

@media (max-width: 1680px) {
  .ps-banner--organic h4 {
    margin-bottom: 10px;
  }
  .ps-banner--organic h3 {
    font-size: 40px;
    line-height: 1.2em;
  }
}

@media (max-width: 1366px) {
  .ps-banner--organic h4 {
    margin-bottom: 10px;
    font-size: 16px;
  }
  .ps-banner--organic h3 {
    font-size: 40px;
  }
  .ps-banner--organic h3 br {
    display: none;
  }
}

@media (max-width: 1199px) {
  .ps-banner--organic {
    min-height: 400px;
  }
  .ps-banner--organic img {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
  .ps-banner--organic .ps-banner__content {
    padding: 0 30px;
  }
}

@media (max-width: 479px) {
  .ps-banner--organic h3 {
    font-size: 24px;
  }
  .ps-banner--organic p {
    font-size: 12px;
  }
}

#homepage-9 a:hover {
  color: #5fa30f;
}

#homepage-9 .ps-product .ps-product__title:hover {
  color: #5fa30f;
}

#homepage-9 .ps-product .ps-product__actions li a:hover {
  background-color: #5fa30f;
  color: #ffffff;
}

#homepage-9 .ps-product--hot-deal .ps-product__thumbnail .ps-product__variants .item.slick-current {
  border-color: #5fa30f;
}

#homepage-9 .ps-product--hot-deal .ps-countdown li span {
  color: #5fa30f;
}

#homepage-9 .ps-product--hot-deal .product__processs-bar .ps-progress span {
  background-color: #5fa30f;
}

#homepage-9 .ps-block--deal-hot {
  border-color: #5fa30f;
}

#homepage-9 .ps-block--testimonial .ps-block__content i {
  color: #5fa30f;
}

#homepage-9 .ps-home-banner .owl-slider .owl-nav {
  left: 50%;
  max-width: 1200px;
  margin: 0 auto;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#homepage-9 .ps-home-banner .owl-slider .owl-nav > * {
  width: 48px;
  height: 48px;
}

#homepage-9 .ps-site-features {
  padding: 50px 0;
}

#homepage-9 .ps-site-features .ps-block--site-features i {
  color: #5fa30f;
}

#homepage-9 .ps-top-categories {
  padding-bottom: 30px;
}

#homepage-9 .ps-top-categories .ps-block--category:hover {
  border-color: #5fa30f;
}

#homepage-9 .ps-product-list .ps-section__header h3 {
  font-size: 20px;
}

#homepage-9 .ps-product-list .row {
  margin: 0;
}

#homepage-9 .ps-product-list .row > * {
  padding: 0;
}

#homepage-9 .ps-product-list .ps-product {
  margin-bottom: 30px;
}

#homepage-9 .ps-deal-hot {
  padding: 50px 0;
}

#homepage-9 .ps-home-blog {
  margin-bottom: 40px;
  padding-top: 60px;
}

#homepage-9 .ps-footer {
  border-top: 1px solid #e1e1e1;
  background-color: #f8f8f8;
}

#homepage-9 .ps-footer .ps-form--newletter .form-group--nest button {
  background-color: #5fa30f;
  color: #ffffff;
}

@media (min-width: 1680px) {
  #homepage-9 .ps-product-list .row .col-xl-3 {
    max-width: calc(100% / 5);
  }
  #homepage-9 .ps-top-categories .row .col-xl-2 {
    max-width: calc(100% / 6);
  }
}
